/* --- MOBILE STYLES --- */

.companyTitle {
  font-size: 12px;
  padding: 10px 0;
}

.portfolio {
  height: calc(100% - 70px);
  width: calc(70%);
  flex-direction: column;
  padding-bottom: var(--spacing);
}

.projectCard {
  width: 100%; /* Makes it responsive */
  color: white;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  padding: 15px !important;
  cursor: pointer;
}

.textWrap {
  transition: all 0.9s ease-in-out;
  z-index: 0;
}

.projectLink {
  text-decoration: none;
}

.imageContainer img {
  height: 12em;
  margin-top: 25px;
  transition: all 1.3s ease-in-out;
}

.tech {
  font-size: 12px;
  white-space: nowrap;
}

.viewWork {
  font-size: 14px;
}

.projectTitle {
  font-size: 15px;
  padding: 7px 0;
}

.githubLink {
  text-decoration: none;
}

.projectCard::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.closeModal {
  margin-left: 250px;
  margin-top: -20px;
}

.closeModal:hover {
  filter: brightness(100);
}

.modalTitle {
  color: white;
  white-space: nowrap;
}

.projectDescription {
  margin-bottom: 10px;
}

.projectModalTitle {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 700;
  line-height: 2;
  color: #777777;
}

/* --- MEDIA QUEIRES --- */

@media (min-width: 750px) {
  .full-screen {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .companyTitle {
    font-size: 12px;
    padding: 10px 0;
  }

  .projectCard {
    flex-direction: row;
    padding: var(--sm-spacing) !important;
    height: 150px;
  }

  .portfolio {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .projectCard:hover::before {
    opacity: 0;
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .textWrap {
    text-shadow: none;
  }

  .projectTitle {
    font-size: 15px;
    padding: 7px 0;
  }

  .viewWork {
    font-size: 16px;
  }

  .tech {
    font-size: 14px;
  }
}
