/* --- MOBILE STYLES --- */

.full-screen {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 0 100px 0;
  overflow-y: auto;
  background: linear-gradient(
    to bottom,
    var(--bg-color) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
  min-height: 100%;
  width: calc(70%);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-out;
}

.about.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.about.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

.header-container {
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  position: relative;
  padding-bottom: 15px;
}

.header-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--hl-color),
    transparent
  );
}

.about a {
  font-family: "Playfair Display", serif;
  font-size: 14; /* Adjust size */
  color: #4682b4; /* SteelBlue color */
  font-weight: 400; /* Bold text */
  text-decoration: none;
}

.italic-text {
  font-style: italic; /* Make it italic */
}

.infoDescription {
  margin-bottom: 2%;
}

a:hover {
  text-decoration: underline; /* Adds underline on hover */
}

.backButton {
  background: none;
  border: none;
  color: inherit;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  transition: transform 0.3s ease;
  padding: 8px;
  border-radius: 50%;
}

.backButton:hover {
  transform: translateX(-5px);
  background: rgba(255, 255, 255, 0.1);
}

.backIcon {
  font-size: 30px;
  color: white;
  margin-right: 8px;
}

.imageContainer {
  width: 150px; /* Account for scaled size */
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalImage {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  border: 3px solid transparent;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  padding: 5px;
}

.personalImage:hover {
  transform: scale(1.05);
  border-color: var(--hl-color);
}

.contentDescription {
  color: var(--secondary-text-color);
  line-height: 1.6;
  text-align: justify;
  max-width: 800px;
  margin: 0 auto;
}

.infoContainer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 25px;
  margin: 20px 0;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.infoContainer a {
  color: var(--hl-color);
  transition: all 0.3s ease;
  position: relative;
  text-decoration: none;
}

.infoContainer a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: var(--hl-color);
  transition: width 0.3s ease;
}

.infoContainer a:hover::after {
  width: 100%;
}

/* Scrollbar styling */
.full-screen::-webkit-scrollbar {
  width: 8px;
}

.full-screen::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.full-screen::-webkit-scrollbar-thumb {
  background: var(--hl-color);
  border-radius: 4px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--sm-spacing);
}

.about .socialIcons .icon {
  display: none;
}

@media (min-width: 768px) {
  .full-screen {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0 100px 0;
    overflow-y: auto;
  }

  .header-container {
    display: flex;
    width: 100%;
    margin-bottom: 5%;
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing);
    min-height: 100%;
    width: calc(50% - 70px);
  }

  .about .socialIcons .icon {
    display: inline;
  }

  .about .socialIcons::before {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .backIcon {
    font-size: 30px;
    color: white;
    margin-right: 8px;
  }

  .backButton {
    background: none;
    border: none;
    color: inherit;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    margin-right: 10px;
  }

  .personalImage {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    border: 3px solid transparent;
    background: linear-gradient(
      145deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.05)
    );
    padding: 5px;
  }

  .personalImage:hover {
    transform: scale(1.05);
    border-color: var(--hl-color);
  }

  .contentDescription {
    color: var(--secondary-text-color);
  }

  .infoContainer {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease;
  }

  .infoContainer:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: var(--sm-spacing);
  }

  .about .socialIcons .icon {
    display: none;
  }
}
